import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './LoginPage.css';
import { BASE_URL } from '../../../services/constants';
import Cookies from 'js-cookie';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [error, setError] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showToggleLink, setShowToggleLink] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (isRegistering) {
                    handleRegister();
                } else {
                    handleLogin();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isRegistering, username, password]);

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/auth/login`, { username, password });
            if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem('token', token);

                const isDevelopment = process.env.NODE_ENV === 'development';
                const domain = isDevelopment ? 'localhost' : '.interactive-assessment.oblakogroup.ru';

                Cookies.set('auth_token', token, {
                    path: '/',
                    domain: domain,
                    sameSite: 'None',
                    secure: true
                });

                const decoded = jwtDecode(token);

                console.log('Decoded token:', decoded);  // Добавьте эту строку
                console.log('User role:', decoded.role);  // И эту

                const navigationState = {
                    nickname: decoded.username,
                    userId: decoded.id,
                    role: decoded.role
                };

                console.log('Navigation state:', navigationState);  // И эту

                navigate('/dashboard', { state: navigationState });
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.response?.data?.message || 'Invalid username or password');
        }
    };

    const handleRegister = async () => {
        if (!username || !email || !password || !role) {
            setError("All fields are required");
            return;
        }

        try {
            const response = await axios.post(
                `${BASE_URL}/users`,
                { username, email, password, role }
            );

            if (response.status === 200) {
                // After successful registration, automatically log in
                await handleLogin();
            }
        } catch (error) {
            console.error('Registration error:', error);
            setError(error.response?.data?.message || 'Registration failed');
        }
    };

    const toggleForm = () => {
        if (isAnimating) return;
        setIsAnimating(true);
        setTimeout(() => {
            setIsRegistering(!isRegistering);
            setError('');
            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        }, 500);
    };

    return (
        <div className="login-container">
            <div className={`form-container ${isRegistering ? 'hidden' : 'visible'} ${isAnimating ? 'animating' : ''}`}>
                <h1>Login</h1>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Login</button>
                {error && <p className="error">{error}</p>}
            </div>
            <div className={`form-container ${isRegistering ? 'visible' : 'hidden'} ${isAnimating ? 'animating' : ''}`}>
                <h1>Register</h1>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Role (user or admin)"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                />
                <button onClick={handleRegister}>Create Account</button>
                {error && <p className="error">{error}</p>}
            </div>
            {/*{showToggleLink && (*/}
            {/*    <p onClick={toggleForm} className="toggle-link">*/}
            {/*        {isRegistering ? 'Enter in account' : 'Create account'}*/}
            {/*    </p>*/}
            {/*)}*/}
        </div>
    );
};

export default LoginPage;
